import './App.css';
import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import os from './assets/opensea.png';
import twitter from './assets/twitter.png';
import hobo from './assets/7.png';
import hobo1 from './assets/updated2.png';
import hobo2 from './assets/updated3.png';
import logo2 from './assets/logo2.png';
import './star.scss';
import Countdown from 'react-countdown';
import './counter.css';
import name from './assets/name.gif';
import audio from './audio/audio.webm';
import ser from './assets/Ser-Button.png';
import etherscan from './assets/Etherscan.png';

//audio.loop=true;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


const opensea = () => {
	window.open("https://opensea.io/collection/the-ser-society");
}

const ether = () => {
	window.open("https://etherscan.io/address/0x28704e4d93a0d6a34d9bc663a01f7dd68cfd90da");
}

const tweet = () => {
	window.open("https://twitter.com/thesersociety");
}


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Mint Now";
let mintStatus = ''
let cost = '';
let maxPerWallet = '';
let publicSaleLimit = '';
let wlClaimed = '';
let owner1 = '';
let owner2 = '';
let owner3 = '';
let whitelist_status = '';
let public_freemints_taken = '';
let new_free_allocation = '';
let freemint_allocation = '';
let revealed = '';
let whitelist_status2 = '';
let baseURI = '';
let public_status = '';
let _revealed = '';
let soundfinder;
let soundfinder1;
let song;
let song1;
let paused;
let whitelistCost;
let special_price;
let _nft_Contract_1;
let _nft_Contract_2;
let publicSale1;
let publicSale2;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [
	"0x19eDA7cb50D5F70fC0B4A1cA04b5Bb17Df99a2f1",
	"0x413131ADe5BD412B2CFB224b671C350aC76C24Ca",
	"0x254A888C0f009Da819D5CfE530721ef6ee622eCB",
	"0xE19763b54021Ad2A0638a4a5BC8149948Af14DF8",
	"0xaa0c97c824a0B82E5aC192E1369feD4c57BaE9cb",

	"0xe7cc0D9Cff3b5C4A1b337a5F3d951a20Ca0fef70",
	"0xCfAeB5bb7df9eaC13499De60Af30838998D91E81",
	"0x5dD30f9FBd126bCaf5a0cD4A6875c88D4bc3e4eF"

];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "__nft_Contract_1",
				"type": "address"
			}
		],
		"name": "setNft_Contract_1",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "__nft_Contract_2",
				"type": "address"
			}
		],
		"name": "setNft_Contract_2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_special_price",
				"type": "uint256"
			}
		],
		"name": "setSpecial_price",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_whitelistCost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "newWhitelistSigner",
				"type": "bytes32"
			}
		],
		"name": "setWhitelistSigner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32[]",
				"name": "_proof",
				"type": "bytes32[]"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft1",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft2",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "special_price",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSigner",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const nft_1_ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "_partslink",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "byebye",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "goblinbyebye",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "goblins",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "howmanygobblins",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "parts",
				"type": "string"
			}
		],
		"name": "makegobblinhaveparts",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "lords",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_goblins",
				"type": "uint256"
			}
		],
		"name": "makegoblinnnfly",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_bye",
				"type": "bool"
			}
		],
		"name": "makegoblngobyebye",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "makingobblin",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_byebye",
				"type": "uint256"
			}
		],
		"name": "spredgobblins",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "sumthinboutfunds",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

const nft_2_ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_tokenName",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_tokenSymbol",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxSupply",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxMintAmountPerTx",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "_hiddenMetadataUri",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "InvalidQueryRange",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "explicitOwnershipOf",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "addr",
						"type": "address"
					},
					{
						"internalType": "uint64",
						"name": "startTimestamp",
						"type": "uint64"
					},
					{
						"internalType": "bool",
						"name": "burned",
						"type": "bool"
					}
				],
				"internalType": "struct IERC721A.TokenOwnership",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "tokenIds",
				"type": "uint256[]"
			}
		],
		"name": "explicitOwnershipsOf",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "addr",
						"type": "address"
					},
					{
						"internalType": "uint64",
						"name": "startTimestamp",
						"type": "uint64"
					},
					{
						"internalType": "bool",
						"name": "burned",
						"type": "bool"
					}
				],
				"internalType": "struct IERC721A.TokenOwnership[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hiddenMetadataUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_teamAmount",
				"type": "uint256"
			}
		],
		"name": "internalMint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxMintAmountPerTx",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "merkleRoot",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			}
		],
		"name": "mintForAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_hiddenMetadataUri",
				"type": "string"
			}
		],
		"name": "setHiddenMetadataUri",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxMintAmountPerTx",
				"type": "uint256"
			}
		],
		"name": "setMaxMintAmountPerTx",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_merkleRoot",
				"type": "bytes32"
			}
		],
		"name": "setMerkleRoot",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPaused",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setRevealed",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_uriPrefix",
				"type": "string"
			}
		],
		"name": "setUriPrefix",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_uriSuffix",
				"type": "string"
			}
		],
		"name": "setUriSuffix",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setWhitelistMintEnabled",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "tokensOfOwner",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "start",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "stop",
				"type": "uint256"
			}
		],
		"name": "tokensOfOwnerIn",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "uriPrefix",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "uriSuffix",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "whitelistClaimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			},
			{
				"internalType": "bytes32[]",
				"name": "_merkleProof",
				"type": "bytes32[]"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistMintEnabled",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

const address = "0x28704E4d93A0d6a34D9bc663A01f7dD68cFD90Da";
const nft_1_Contract = "0xbCe3781ae7Ca1a5e050Bd9C4c77369867eBc307e";
const nft_2_Contract = "0xE6d48bF4ee912235398b96E16Db6F310c21e82CB";

let contract;
let contract1;
let contract2;

class Mint extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "10000000000000000",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Mint Now',
		_mintStatus: '',
		_cost: '',
		_metadataLink: '',
		_maxPerWallet: '',
		_setPS_limit: '',
		_wl_limit: '',
		_publicSaleCost: '',
		_newOwner: '',
		_owner: '',
		_setWhitelistSign: '',
		_publicSaleLimit: '',
		_wlClaimed: '',
		stateNow: Date.now(),
		_whitelist_status: '',
		_public_freemints_taken: '',
		_freemint_allocation: '',
		_new_free_allocation: '',
		_revealed: '',
		_whitelist_status2: '',
		_baseURI: '',
		_public_status: '',
		_revealed: '',
		sound: 0,
		_ser: 0,
		_paused: '',
		_whitelistCost: '',
		_special_price: '',
		__nft_Contract_1: '',
		__nft_Contract_2: '',
		_pausedValue: '',
		_wl_cost: '',
		_special_cost: '',
		_address_1: '',
		_address_2: '',
		myNFTWallet1: '',
		myNFTWallet2: ''
	}

	ser = async event => {
		event.preventDefault();
		if (song != null) {
			song.pause();
		}
		song1 = new Audio(audio);
		song1.play();

		this.setState({ _ser: 1 });
	}

	closeWindow = async event => {
		event.preventDefault();

		this.setState({ _ser: 0 });
		soundfinder1 = 0;
		this.setState({ sound: soundfinder1 });
		song1.pause();


		if (song != null) {
			song.play();
		}
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - this.state.totalSupply;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (this.state.totalSupply < 10000) {

				onlyLeft = this.state._maxPerWallet - this.state.myNFTWallet;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - this.state.totalSupply;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (this.state.totalSupply < 10000) {

				onlyLeft = this.state._maxPerWallet - this.state.myNFTWallet;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {

				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					
						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value:  this.state._cost * mintAmount });
						this.setState({ statusLoading: false, success: true });
						console.log("Mint Amount :" + this.state.mintAmount);
							

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);
				}
			} else {

				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);
				}

				console.log("minted successfully");
				window.location.reload(true);
				

			}
		} catch (err) {

			console.log(err);

		}
		console.log("minting successfully2");
		window.location.reload(true);

	}

	walletConnect = async event => {
		event.preventDefault();

		/*	var audioElement0 = document.createElement('audio');
			audioElement0.setAttribute('src', 'notify.wav');
			audioElement0.setAttribute('autoplay', 'autoplay');
			audioElement0.Play(); */

		song = new Audio(audio);
		song.play();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			contract1 = new web3.eth.Contract(nft_1_ABI, nft_1_Contract);
			console.log("contract1 :" + contract1);

			contract2 = new web3.eth.Contract(nft_2_ABI, nft_2_Contract);
			console.log("contract2 :" + contract2);

			if (provider) {


				(async () => {


					if (provider != "walletconnect") {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Rinkeby Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'RinkebyETH' },
												rpcUrls: ['https://rinkeby.etherscan.io'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}

						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							baseURI = await contract.methods.baseURI().call();
							this.setState({ _baseURI: baseURI });
							console.log("baseURI :" + this.state._baseURI);

							revealed = await contract.methods.revealed().call();
							this.setState({ _revealed: revealed });
							console.log("revealed" + revealed);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							publicSale1 = await contract1.methods.balanceOf(account).call();
							this.setState({ myNFTWallet1: publicSale1 });

							publicSale2 = await contract2.methods.balanceOf(account).call();
							this.setState({ myNFTWallet2: publicSale2 });

							if (publicSale1 > 0 || publicSale2 > 0) {

								cost = await contract.methods.special_price().call();
								this.setState({ _cost: cost });
								console.log("cost :" + cost);									

							}else{

								cost = await contract.methods.publicSaleCost().call();
								this.setState({ _cost: cost });
								console.log("cost :" + cost);

							}	

							maxPerWallet = await contract.methods.max_per_wallet().call();
							this.setState({ _maxPerWallet: maxPerWallet });
							console.log("maxPerWallet :" + maxPerWallet);

							special_price = await contract.methods.special_price().call();
							this.setState({ _special_price: special_price });
							console.log("special_price :" + special_price);

							whitelistCost = await contract.methods.whitelistCost().call();
							this.setState({ _whitelistCost: whitelistCost });
							console.log("whitelistCost :" + whitelistCost);

							paused = await contract.methods.paused().call();
							this.setState({ _paused: paused });
							console.log("paused :" + paused);

							_nft_Contract_1 = await contract.methods._nft_Contract_1().call();
							this.setState({ __nft_Contract_1: _nft_Contract_1 });
							console.log("_nft_Contract_1 :" + _nft_Contract_1);

							_nft_Contract_2 = await contract.methods._nft_Contract_2().call();
							this.setState({ __nft_Contract_2: _nft_Contract_2 });
							console.log("_nft_Contract_2 :" + _nft_Contract_2);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - this.state.totalSupply;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (this.state.totalSupply == 10000) {

									onlyLeft = 10000 - this.state.totalSupply;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10000 - this.state.myNFTWallet;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {



						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}

						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							baseURI = await contract.methods.baseURI().call();
							this.setState({ _baseURI: baseURI });
							console.log("baseURI :" + this.state._baseURI);

							revealed = await contract.methods.revealed().call();
							this.setState({ _revealed: revealed });
							console.log("revealed" + revealed);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							publicSale1 = await contract1.methods.balanceOf(account).call();
							this.setState({ myNFTWallet1: publicSale1 });

							publicSale2 = await contract2.methods.balanceOf(account).call();
							this.setState({ myNFTWallet2: publicSale2 });

							if (publicSale1 > 0 || publicSale2 > 0) {

								cost = await contract.methods.special_price().call();
								this.setState({ _cost: cost });
								console.log("cost :" + cost);									

							}else{

								cost = await contract.methods.publicSaleCost().call();
								this.setState({ _cost: cost });
								console.log("cost :" + cost);

							}	

							maxPerWallet = await contract.methods.max_per_wallet().call();
							this.setState({ _maxPerWallet: maxPerWallet });
							console.log("maxPerWallet :" + maxPerWallet);

							special_price = await contract.methods.special_price().call();
							this.setState({ _special_price: special_price });
							console.log("special_price :" + special_price);

							whitelistCost = await contract.methods.whitelistCost().call();
							this.setState({ _whitelistCost: whitelistCost });
							console.log("whitelistCost :" + whitelistCost);

							paused = await contract.methods.paused().call();
							this.setState({ _paused: paused });
							console.log("paused :" + paused);

							_nft_Contract_1 = await contract.methods._nft_Contract_1().call();
							this.setState({ __nft_Contract_1: _nft_Contract_1 });
							console.log("_nft_Contract_1 :" + _nft_Contract_1);

							_nft_Contract_2 = await contract.methods._nft_Contract_2().call();
							this.setState({ __nft_Contract_2: _nft_Contract_2 });
							console.log("_nft_Contract_2 :" + _nft_Contract_2);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - this.state.totalSupply;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (this.state.totalSupply == 10000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10000 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	transferOwnership = async event => {
		event.preventDefault();

		try {
			await contract.methods.transferOwnership(this.state._newOwner).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	setBaseURI = async event => {
		event.preventDefault();

		try {
			await contract.methods.setBaseURI(this.state._metadataLink).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	set_max_per_wallet = async event => {
		event.preventDefault();

		try {
			await contract.methods.setMax_per_wallet(this.state._setmaxPerWallet).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	setPublicSaleCost = async event => {
		event.preventDefault();

		try {
			await contract.methods.setPublicSaleCost(this.state._publicSaleCost).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	withdraw = async event => {
		event.preventDefault();

		try {
			await contract.methods.withdraw().send({/*gasLimit: 685000, */ from: account, value: 0 });
		} catch (err) {
			console.log(err);
		}
	}

	setWhitelistSign = async event => {
		event.preventDefault();

		try {
			await contract.methods.setWhitelistSigner(this.state._setWhitelistSign).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	toggleReveal = async event => {
		event.preventDefault();

		try {
			await contract.methods.toggleReveal().send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	setWhitelistCost = async event => {
		event.preventDefault();

		try {
			await contract.methods.setWhitelistCost(this.state._wl_cost).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	setSpecial_price = async event => {
		event.preventDefault();

		try {
			await contract.methods.setSpecial_price(this.state._special_cost).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	setNft_Contract_1 = async event => {
		event.preventDefault();

		try {
			await contract.methods.setNft_Contract_1(this.state._address_1).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	setNft_Contract_2 = async event => {
		event.preventDefault();

		try {
			await contract.methods.setNft_Contract_2(this.state._address_2).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	paused = async event => {
		event.preventDefault();

		try {
			await contract.methods.pause(this.state._pausedValue).send({/*gasLimit: 685000, */ from: account });
		} catch (err) {
			console.log(err);
		}
	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	whitelistMint = async event => {
		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });

		if (FinalResult) {
			try {
				//this.setState({ statusError: false, statusLoading: true });
				await contract.methods.whitelistMint(hexProof).send({ gasLimit: 285000, from: account, value: 0 });
				//this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.wMintAmount);

			} catch (err) {
				//this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);
			}

			window.location.reload(true);

		} else {
			wlMsg = "Not Eligible for WL"
			this.setState({ _wlMsg: wlMsg });
		}

	}

	async componentDidMount() {

		if (localStorage?.getItem('isWalletConnected') === 'true') {

			try {

				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "bf933c3446b3464c988114813a1360ac" // required
						}
					}
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("provider :" + web3Modal.cachedProvider);
					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					contract = new web3.eth.Contract(ABI, address);
					console.log("contract :" + contract);

					contract1 = new web3.eth.Contract(nft_1_ABI, nft_1_Contract);
					console.log("contract :" + contract);
		
					contract2 = new web3.eth.Contract(nft_2_ABI, nft_2_Contract);
					console.log("contract :" + contract);
		

					if (provider) {


						(async () => {


							if (provider != "walletconnect") {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}


								const chainId = 1;

								if (window.ethereum.networkVersion !== chainId) {
									try {
										await window.ethereum.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														chainName: 'Ethereum Mainnet',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
														rpcUrls: ['https://mainnet.infura.io/v3/'],
													},
												],
											});
										}
									}
								}

								try {

									try {
										localStorage.setItem('isWalletConnected', true);
									} catch (ex) {
										console.log(ex)
									}


									baseURI = await contract.methods.baseURI().call();
									this.setState({ _baseURI: baseURI });
									console.log("baseURI :" + this.state._baseURI);

									revealed = await contract.methods.revealed().call();
									this.setState({ _revealed: revealed });
									console.log("revealed" + revealed);

									owner = await contract.methods.owner().call();
									this.setState({ _owner: owner });
									console.log("Owner" + owner);

									totalSupplyNFT = await contract.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });

									console.log("Total Supply:" + totalSupplyNFT);

									publicSale = await contract.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									publicSale1 = await contract1.methods.balanceOf(account).call();
									this.setState({ myNFTWallet1: publicSale1 });

									publicSale2 = await contract2.methods.balanceOf(account).call();
									this.setState({ myNFTWallet2: publicSale2 });

									if (publicSale1 > 0 || publicSale2 > 0) {

										cost = await contract.methods.special_price().call();
										this.setState({ _cost: cost });
										console.log("cost :" + cost);									

									}else{

										cost = await contract.methods.publicSaleCost().call();
										this.setState({ _cost: cost });
										console.log("cost :" + cost);

									}	

									maxPerWallet = await contract.methods.max_per_wallet().call();
									this.setState({ _maxPerWallet: maxPerWallet });
									console.log("maxPerWallet :" + maxPerWallet);

									special_price = await contract.methods.special_price().call();
									this.setState({ _special_price: special_price });
									console.log("special_price :" + special_price);

									whitelistCost = await contract.methods.whitelistCost().call();
									this.setState({ _whitelistCost: whitelistCost });
									console.log("whitelistCost :" + whitelistCost);

									paused = await contract.methods.paused().call();
									this.setState({ _paused: paused });
									console.log("paused :" + paused);

									_nft_Contract_1 = await contract.methods._nft_Contract_1().call();
									this.setState({ __nft_Contract_1: _nft_Contract_1 });
									console.log("_nft_Contract_1 :" + _nft_Contract_1);

									_nft_Contract_2 = await contract.methods._nft_Contract_2().call();
									this.setState({ __nft_Contract_2: _nft_Contract_2 });
									console.log("_nft_Contract_2 :" + _nft_Contract_2);

									if (owner == account) {
										console.log("owner : " + owner)
										onlyLeft = 10000 - this.state.totalSupply;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}

										valueOfNFTs = mintAmount * 0;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									} else {
										mintAmount = 1;

										if (this.state.totalSupply == 10000) {

											onlyLeft = 10000 - this.state.totalSupply;
											mintAmount = onlyLeft;
											this.setState({ msg: "SOLD OUT" });

										} else {
											mintAmount = 1;
											onlyLeft = 10000 - this.state.myNFTWallet;

											if (mintAmount > onlyLeft) {
												mintAmount = onlyLeft;
											}
											//mintAmount = onlyLeft;

											valueOfNFTs = mintAmount * this.state._cost;
											wMintAmount = mintAmount;


											this.setState({ nftMintingAmount: mintAmount });

											this.setState({ totalValue: valueOfNFTs });
										}
									}


								} catch (err) {
									console.log("err: " + err);

								}
							} else {



								if (WalletConnectProvider.networkVersion !== chainId) {
									try {
										await WalletConnectProvider.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														chainName: 'Ethereum Mainnet',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
														rpcUrls: ['https://mainnet.infura.io/v3/'],
													},
												],
											});
										}
									}
								}




								try {

									try {
										localStorage.setItem('isWalletConnected', true);
									} catch (ex) {
										console.log(ex)
									}


									baseURI = await contract.methods.baseURI().call();
									this.setState({ _baseURI: baseURI });
									console.log("baseURI :" + this.state._baseURI);

									revealed = await contract.methods.revealed().call();
									this.setState({ _revealed: revealed });
									console.log("revealed" + revealed);

									owner = await contract.methods.owner().call();
									this.setState({ _owner: owner });
									console.log("Owner" + owner);

									totalSupplyNFT = await contract.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });

									console.log("Total Supply:" + totalSupplyNFT);

									publicSale = await contract.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									publicSale1 = await contract1.methods.balanceOf(account).call();
									this.setState({ myNFTWallet1: publicSale1 });

									publicSale2 = await contract2.methods.balanceOf(account).call();
									this.setState({ myNFTWallet2: publicSale2 });

									if (publicSale1 > 0 || publicSale2 > 0) {

										cost = await contract.methods.special_price().call();
										this.setState({ _cost: cost });
										console.log("cost :" + cost);									

									}else{

										cost = await contract.methods.publicSaleCost().call();
										this.setState({ _cost: cost });
										console.log("cost :" + cost);

									}								

									maxPerWallet = await contract.methods.max_per_wallet().call();
									this.setState({ _maxPerWallet: maxPerWallet });
									console.log("maxPerWallet :" + maxPerWallet);								

									whitelistCost = await contract.methods.whitelistCost().call();
									this.setState({ _whitelistCost: whitelistCost });
									console.log("whitelistCost :" + whitelistCost);

									paused = await contract.methods.paused().call();
									this.setState({ _paused: paused });
									console.log("paused :" + paused);

									_nft_Contract_1 = await contract.methods._nft_Contract_1().call();
									this.setState({ __nft_Contract_1: _nft_Contract_1 });
									console.log("_nft_Contract_1 :" + _nft_Contract_1);

									_nft_Contract_2 = await contract.methods._nft_Contract_2().call();
									this.setState({ __nft_Contract_2: _nft_Contract_2 });
									console.log("_nft_Contract_2 :" + _nft_Contract_2);

									special_price = await contract.methods.special_price().call();
									this.setState({ _special_price: special_price });
									console.log("special_price :" + special_price);

									if (owner == account) {
										console.log("owner : " + owner)
										onlyLeft = 10000 - this.state.totalSupply;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}

										valueOfNFTs = mintAmount * 0;

										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									} else {
										mintAmount = 1;

										if (this.state.totalSupply == 10000) {

											onlyLeft = 0;

											if (mintAmount > onlyLeft) {
												mintAmount = onlyLeft;
											}
											this.setState({ msg: "SOLD OUT" });

										} else {
											mintAmount = 1;
											onlyLeft = 10000 - publicSale;

											if (mintAmount > onlyLeft) {
												mintAmount = onlyLeft;
											}

											valueOfNFTs = mintAmount * this.state._cost;
											wMintAmount = mintAmount;


											this.setState({ nftMintingAmount: mintAmount });

											this.setState({ totalValue: valueOfNFTs });
										}
									}


								} catch (err) {
									console.log("err: " + err);

								}
							}
						})();

						//.....................................................................//






						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}


				}

			} catch (err) {

				console.log(err);
			}

		}

	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="headers">

						<div class="headers2">

							<div class="logo"><img class="logoPic" src={logo2} /></div>

							<div class="navBar">

							</div>
							<div class="right">
								<div class="icons">
									<div class="discord"><img class="osPic" onClick={opensea} src={os} /></div>

									<div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>

									<div class="discord"><img class="osPic" onClick={this.ser} src={ser} /></div>

									<div class="discord"><img class="osPic" onClick={ether} src={etherscan} /></div>

									<div class="discord2"><img class="osPic" onClick={opensea} src={os} /></div>

									<div class="discord2">
										<SocialIcon class="twitter" url="#" target="_blank" network="twitter" bgColor="#ffffff" style={{ height: 60, width: 60 }} />
									</div>

									<div class="discord3"><img class="osPic" onClick={opensea} src={os} /></div>

									<div class="discord3">
										<SocialIcon class="twitter" url="#" target="_blank" network="twitter" bgColor="#ffffff" style={{ height: 75, width: 75 }} />
									</div>

									<div class="discord4"><img class="osPic" onClick={opensea} src={os} /></div>

									<div class="discord4">
										<SocialIcon class="twitter" url="#" target="_blank" network="twitter" bgColor="#ffffff" style={{ height: 100, width: 100 }} />
									</div>
								</div>
								<div class="connect2">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect}>
											<button class="wallet2" >Connect Wallet</button>
										</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>

							</div>

						</div>

						{this.state._ser > 0 ?

							(<div class="formMain1" >
								<div class="formMain">

									<div class="ser">
										<p>We, the Sers, belongeth to a highly sophisticated, old-fashion'd society, dating backeth to the vict'rian era. Thee belikly hath heard of us bef're, but thee has't nev'r seen us, as we liketh to stayeth amongst ourselves. But anon we figur'd t is about timeth to showeth our faces. We art doing so by paying tribute to our belov'd nft space and its most degen projects. Those projects art anon officially SER-chipp'd. Enjoyeth the fame. Thee has did earn t.</p>
										<p>One m're thing. As we art a v'ry uld folk, we usually art black and white. But some of us doth not playeth by the rules and those gents art trying out those folk colours. Doth not be did confuse by those folk.</p>
										<p>We did get lots of stuffeth to showeth to the w'rld. Howev'r the w'rld is not re'dy yet. T is not degen enoughth. We shall reevaluate lief in the near future… tick tock.</p>
									</div>
									<form onSubmit={this.closeWindow}>
										<button class="closeNote2">✖</button>
									</form>
								</div>
								<div class="closeNote">
									<form onSubmit={this.closeWindow}>
										<button >✖</button>
									</form>
								</div>

							</div>) : null
						}

					</div>

					{this.state.stateNow >= 1659642566000 ?
						(<div class="introduction">

							<div class="in2">
								<div class="intro">
									The Ser Society
								</div>

								<div class="intro2">We, the Sers, art finally paying tribute to the whole NFT space and our fav'rite projects.  Just an hommage.  Nay affiliation.  CC0.
									<br />
									#theserspayingtribute</div>

								<Element name="mint">
									<div class="nftblockWalletConnectedALL">

										{this.state.walletAddress === '' ? (


											<div class="walletConnect">
												{this.state.stateNow >= 1659642566000 ? (
													<form onSubmit={this.walletConnect}>
														<button class="wallet3" type='submit'><div class="shakeWord">MINT NOW</div></button>
													</form>) :

													<div class="countdown">
														<Countdown
															date={1659642566000}
															renderer={renderer}
														/>
													</div>

												}
											</div>
										) : null

										}
									</div>
								</Element>
							</div>

							{this.state.walletAddress !== '' && this.state.stateNow >= 1659642566000 ?

								(<div class="mintDiv">
										<div class="totalSupply">{this.state.totalSupply}/10000</div>

										<div class="price"><div>Mint Now {this.state._cost / 1000000000000000000} ETH</div></div>
									<div class="minting_count_button">
										<div class="center">
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div class="nftminter2">{this.state.nftMintingAmount}</div>

										<div class="center">
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2-2" type="submit">+</button>
											</form>
										</div>
									</div>

									<div class="mintbuttondiv">
										<form onSubmit={this.onSubmit2}>
											<button class="btnfos-0-3" type="submit">
												<div class="shakeWord">Mint Now</div></button>
										</form>
									</div>
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your SER</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div><div class="successfully">🎩 MINTING SUCCESSFUL! 🎩</div>
											</div>)
											: null}

									</div></div>) : (<div class="beastDiv">
										<img class="beast" src={logo2} />
									</div>)}

						</div>) :

						(<div class="introduction">

							<div class="in2">
								<div class="intro">
									The Ser Society
								</div>

								<Element name="mint">
									<div class="nftblockWalletConnectedALL">

										<div class="walletConnect">

											<div class="countdown">
												<Countdown
													date={1659642566000}
													renderer={renderer}
												/>
											</div>



										</div>

									</div>
								</Element>
							</div>


							(<div class="beastDiv">
								<img class="beast" src={hobo} />
							</div>)

						</div>)}

					{this.state.walletAddress !== '' && this.state._owner === this.state.walletAddress ?
						(<div id="faq" class="faqbg">

							<div class="accordin reveal">

								<div class="faqTitle reveal">Admin Panel</div>

								<details >
									{this.state._paused === true ?
										(<summary >1. Pause Minting - <span class="status">Status: true</span></summary>) :
										(<summary >1. Pause Minting - <span class="status">Status: false</span>  </summary>)}

									<div class="faq__content">
										<form onSubmit={this.paused}>
											<input class="mintingAmount" type="text" name="paused" placeholder="true or false" value={this.state._pausedValue}
												onChange={event => this.setState({ _pausedValue: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details >

								<details >
									<summary>2. Change Metadata Link - <span class="status">Status: {this.state._baseURI}</span> </summary>
									<div class="faq__content">

										<form onSubmit={this.setBaseURI}>
											<input class="mintingAmount" type="text" name="setBaseURI" placeholder="ipfs link" value={this.state._metadataLink}
												onChange={event => this.setState({ _metadataLink: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details >
									<summary>3. Set Maximum Mints Available for a Wallet - <span class="status">Status: {this.state._maxPerWallet}</span> </summary>
									<div class="faq__content">

										<form onSubmit={this.set_max_per_wallet}>
											<input class="mintingAmount" type="text" name="_setmaxPerWallet" placeholder="amount" value={this.state._setmaxPerWallet}
												onChange={event => this.setState({ _setmaxPerWallet: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>4. Change the Minting Price Per NFT - <span class="status">Status: {this.state._cost}</span></summary>
									<div class="faq__content">

										<form onSubmit={this.setPublicSaleCost}>
											<input class="mintingAmount" type="text" name="setPublicSaleCost" placeholder="amount (WEI)" value={this.state._publicSaleCost}
												onChange={event => this.setState({ _publicSaleCost: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>5. Change the WL Price Per NFT - <span class="status">Status: {this.state._whitelistCost}</span></summary>
									<div class="faq__content">

										<form onSubmit={this.setWhitelistCost}>
											<input class="mintingAmount" type="text" name="setWhitelistCost" placeholder="amount (WEI)" value={this.state._wl_cost}
												onChange={event => this.setState({ _wl_cost: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>6. Change the Special Price Per NFT - <span class="status">Status: {this.state._special_price}</span></summary>
									<div class="faq__content">

										<form onSubmit={this.setSpecial_price}>
											<input class="mintingAmount" type="text" name="setSpecial_price" placeholder="amount (WEI)" value={this.state._special_cost}
												onChange={event => this.setState({ _special_cost: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>7. Transfer the Ownership - <span class="status">Status: {this.state._owner}</span> </summary>
									<div class="faq__content">

										<form onSubmit={this.transferOwnership}>
											<input class="mintingAmount" type="text" name="transferOwnership" placeholder="new owner" value={this.state._newOwner}
												onChange={event => this.setState({ _newOwner: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>8. Withdraw Your Funds</summary>
									<div class="faq__content">
										<form onSubmit={this.withdraw}>
											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>9. Set Whitelist Sign - <span class="status">Status: {this.state._setWhitelistSign}</span></summary>
									<div class="faq__content">
										<form onSubmit={this.setWhitelistSign}>
											<input class="mintingAmount" type="text" name="setWhitelistSign" placeholder="Whitelist Sign" value={this.state._setWhitelistSign}
												onChange={event => this.setState({ _setWhitelistSign: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>10. Set Special NFT Project 1 - <span class="status">Status: {this.state.__nft_Contract_1}</span></summary>
									<div class="faq__content">
										<form onSubmit={this.setNft_Contract_1}>
											<input class="mintingAmount" type="text" name="setNft_Contract_1" placeholder="Address 1" value={this.state._address_1}
												onChange={event => this.setState({ _address_1: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									<summary>11. Set Special NFT Project 2 - <span class="status">Status: {this.state.__nft_Contract_2}</span></summary>
									<div class="faq__content">
										<form onSubmit={this.setNft_Contract_2}>
											<input class="mintingAmount" type="text" name="_address_2" placeholder="Address 2" value={this.state._address_2}
												onChange={event => this.setState({ _address_2: event.target.value })}></input>

											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>

								<details reveal>
									{this.state._revealed === true ?
										(<summary >12. Toggle Reveal - <span class="status">Status: NFTs are Revealed</span></summary>) :
										(<summary >12. Toggle Reveal - <span class="status">Status: NFTs are Not Revealed</span>  </summary>)}
									<div class="faq__content">
										<form onSubmit={this.toggleReveal}>
											<button class="writebtn">Execute</button>
										</form>
									</div>
								</details>


							</div>
						</div>) : null}

				</div>
			</div >)
	}
}

export default Mint;
